import { render, staticRenderFns } from "./CommunityRoles.vue?vue&type=template&id=8c7dddea&scoped=true&"
import script from "./CommunityRoles.vue?vue&type=script&lang=js&"
export * from "./CommunityRoles.vue?vue&type=script&lang=js&"
import style0 from "./CommunityRoles.vue?vue&type=style&index=0&id=8c7dddea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c7dddea",
  null
  
)

export default component.exports